@import url('https://fonts.googleapis.com/css?family=Arapey&display=swap');

html {
    box-sizing: border-box;
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    font-weight: 400;
    height: 100%;
    margin: 0;
    line-height: 1.15;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
} 

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button::-moz-focus-inner {
    border: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

.grid-sizer { width: 25%; }

a, a:active, a:focus{
	outline:none !important;
}

#root {
    height: 100%;
}

.loading {
    opacity: 0.5;
}